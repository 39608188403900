import React, {Component} from 'react';
import {inject, observer} from 'mobx-react';
import {Redirect} from 'react-router-dom';
import {object} from 'prop-types';
import styled from 'styled-components';
import media from './../../../media';
import Typography from './../../Page/Typography';
import LoadingPage from './../LoadingPage';
import Page from './../../Page';
import Card from './../../Page/Card';
import CardTitle from './../../Page/Card/CardTitle';
import ArchivedSession from './ArchivedSession';

const TextBlock = styled.div`
  font-size: 18px;
  margin: 24px 32px;
  text-align: center;

  ${media.mobile`
    font-size: 14px;
  `}
`;

const Divider = styled.div`
  width: 100%;
  width: calc(100% - 60px);
  height: 1px;
  background-color: #BBBBBB;
  margin: 10px 30px;
`;

@inject('uiStore') @inject('sessionsStore') @observer
class ArchivedSessionsListPage extends Component {
  componentDidMount () {
    this.props.sessionsStore.loadIfNeed();
    this.updateUIStore();
  }

  componentDidUpdate () {
    this.updateUIStore();
  }

  updateUIStore () {
    const {uiStore, sessionsStore} = this.props;

    uiStore.title = sessionsStore.archiveOnly ? 'Video Sessions' : 'Archive';
    uiStore.backUrl = !sessionsStore.archiveOnly ? '/' : '';
  }

  render () {
    const {props: {sessionsStore: {archived = [], global = [], isInProgram, loading, useModuleSessions}}} = this;

    if (useModuleSessions) {
      return <Redirect to={{pathname: '/module'}} />;
    }

    if (loading) {
      return (<LoadingPage />);
    }

    const videos = [...archived, ...global];

    if (videos.length < 1) {
      return (
        <Page>
          <Card>
            <CardTitle>
              Your first session is not available yet
            </CardTitle>
          </Card>
        </Page>)
      ;
    }

    return (
      <Page>
        <Card noSidePadding>
          {isInProgram ?
            <Typography type='body1'>
              <TextBlock>
              A new video session becomes available on the same day each week
              </TextBlock>
            </Typography> : null}
          {global.slice().reverse().map((session) => <ArchivedSession key={session._id} session={session} />)}
          {global.length ? <Divider /> : null}
          {archived.slice().reverse().map((session) => <ArchivedSession key={session._id} session={session} />)}
        </Card>
      </Page>
    );
  }
}

ArchivedSessionsListPage.propTypes = {
  uiStore: object,
  sessionsStore: object
};

export default ArchivedSessionsListPage;
