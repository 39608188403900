import React, {Component} from 'react';
import {inject, observer} from 'mobx-react';
import {Redirect} from 'react-router-dom';
import {object} from 'prop-types';
import LoadingPage from './../LoadingPage';
import ErrorPage from './../ErrorPage';
import ActiveSessionPage from './ActiveSessionPage';
import UpcomingSessionPage from './UpcomingSessionPage';
import SessionCounterPage from './SessionCounterPage';
import OnlyArchivedSessionsPage from './OnlyArchivedSessionsPage';

@inject('uiStore')  @inject('sessionsStore') @observer
class ActiveSessionPagePage extends Component {
  componentDidMount () {
    const {uiStore, sessionsStore} = this.props;

    uiStore.title = '';
    uiStore.backUrl = '';

    sessionsStore.loadIfNeed();
    this.setTitle();
  }

  componentDidUpdate() {
    this.setTitle();
  }

  setTitle () {
    const {uiStore, sessionsStore} = this.props;

    uiStore.title = sessionsStore.active ? sessionsStore.active.title : '';
  }

  handleActiveSessionEnd = () => {
    this.props.sessionsStore.load();
  }

  render () {
    const {sessionsStore} = this.props;

    if (sessionsStore.loading) {
      return (
        <LoadingPage />
      );
    }

    if (sessionsStore.useModuleSessions) {
      return <Redirect to={{pathname: '/module'}} />;
    }

    if (sessionsStore.loadError) {
      return (
        <ErrorPage text={sessionsStore.loadError} />
      );
    }

    if (sessionsStore.archiveOnly) {
      return <Redirect to={{pathname: '/archive'}} />;
    }

    if (sessionsStore.active) {
      return (
        <ActiveSessionPage session={sessionsStore.active}
          startedAt={sessionsStore.activeSessionStartDate}
          onEnd={this.handleActiveSessionEnd}
        />
      );
    }

    if (sessionsStore.counter) {
      return (
        <SessionCounterPage session={sessionsStore.upcoming} counter={sessionsStore.counter} />
      );
    }

    if (sessionsStore.upcoming) {
      return (
        <UpcomingSessionPage session={sessionsStore.upcoming}
          withArchive={!!(sessionsStore.archived && sessionsStore.archived.length)}
        />
      );
    }

    if (sessionsStore.archived && sessionsStore.archived.length) {
      return (
        <OnlyArchivedSessionsPage />
      );
    }

    return (
      <ErrorPage text='No Sessions Found' />
    );
  }
}

ActiveSessionPagePage.propTypes = {
  uiStore: object,
  sessionsStore: object
};

export default ActiveSessionPagePage;
