import React, {Component} from 'react';
import styled from 'styled-components';
import {number, node, oneOfType, arrayOf, bool} from 'prop-types';
import media from './../../../media';

const Container = styled.div`
  max-width: ${({width}) => width + 32}px;
  width: 100%;
  box-sizing: border-box;
  padding: 16px;
  margin: auto;
`;

const Body = styled.div`
  border-radius: 4px;
  background-color: #ffffff;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.5);
  width: 100%;
  box-sizing: border-box;
  position: relative;

  overflow: hidden;

  ${({noSidePadding}) => (noSidePadding ? 'padding: 16px 0;' : 'padding: 16px 32px;')}

  ${media.mobile`
    padding: 8px;
  `}
`;

class Card extends Component {
  render () {
    const {width, noSidePadding} = this.props;

    return (
      <Container width={width}>
        <Body width={width} noSidePadding={!!noSidePadding}>
          {this.props.children}
        </Body>
      </Container>
    );
  }
}

Card.defaultProps = {
  width: 665
};

Card.propTypes = {
  children: oneOfType([arrayOf(node), node]),
  noSidePadding: bool,
  width: number
};

export default Card;

