import React, {Component} from 'react';
import {inject, observer} from 'mobx-react';
import {object, number, func} from 'prop-types';
import Page from './../../Page';
import SessionVideo from './SessionVideo';
import Chat from './Chat';

@inject('uiStore')  @observer
class ActiveSessionPage extends Component {
  handleTriggerChat = () => {
    const {uiStore} = this.props;

    uiStore.showChat = !uiStore.showChat;
  }

  render () {
    const {session, startedAt, uiStore} = this.props;

    return (
      <Page>
        <SessionVideo videoId={session.vimeo_id} startedAt={startedAt} onEnd={this.props.onEnd} />
        <Chat show={uiStore.showChat} onTrigger={this.handleTriggerChat} />
      </Page>
    );
  }
}

ActiveSessionPage.propTypes = {
  uiStore: object,
  session: object,
  startedAt: number,
  onEnd: func
};

export default ActiveSessionPage;
