/* globals __API_URL__ */
import React, {Component} from 'react';
import {Switch, Route} from 'react-router-dom';
import {Provider} from 'mobx-react';
import store from 'store';
import AxiosTransportLayer from './../transport_layers/AxiosTransportLayer';

import UIStore from './../stores/UIStore';
import AuthStore from './../stores/AuthStore';
import SessionsStore from './../stores/SessionsStore';
import ModuleStore from './../stores/ModuleStore';

import PrivateRoute from './PrivateRoute';

import NotFoundPage from './pages/NotFoundPage';
import LoginPage from './pages/LoginPage';
import ForgotPasswordPage from './pages/ForgotPasswordPage';
import ResetPasswordPage from './pages/ResetPasswordPage';
import SessionPage from './pages/SessionPage';
import ArchivedSessionsListPage from './pages/ArchivedSessionsListPage';
import ViewArchivedSessionPage from './pages/ViewArchivedSessionPage';
import ModuleSessionsPage from './pages/ModuleSessionsPage';
import ModuleSessionsStepPage from './pages/ModuleSessionsStepPage';
import ModuleSessionsComponentPage from './pages/ModuleSessionsComponentPage';

import 'normalize.css/normalize.css';

const transportLayer = new AxiosTransportLayer(__API_URL__);

const uiStore = new UIStore(store);
const authStore = new AuthStore(transportLayer, store);
const sessionsStore = new SessionsStore(transportLayer);
const moduleStore = new ModuleStore(transportLayer);

hotReloadStores([uiStore, sessionsStore]);

export default class App extends Component {
  render () {
    return (
      <Provider uiStore={uiStore} authStore={authStore}
        sessionsStore={sessionsStore} moduleStore={moduleStore}
      >
        <Switch>
          <Route exact path='/login' component={LoginPage} />
          <Route exact path='/forgot_password' component={ForgotPasswordPage} />
          <Route exact path='/reset_password' component={ResetPasswordPage} />
          <PrivateRoute path='/' component={ProtectedArea} />
        </Switch>
      </Provider>
    );
  }
}

const ProtectedArea = () => (
  <Switch>
    <Route exact path='/' component={SessionPage} />
    <Route exact path='/archive' component={ArchivedSessionsListPage} />
    <Route exact path='/archive/:id' component={ViewArchivedSessionPage} />
    <Route exact path='/module' component={ModuleSessionsPage} />
    <Route exact path='/module/steps/:id' component={ModuleSessionsStepPage} />
    <Route exact path='/module/steps/:stepId/components/:id' component={ModuleSessionsComponentPage} />
    <Route component={NotFoundPage} />
  </Switch>
);


function hotReloadStores (storesToHodReload) {
  if (module.hot) {
    storesToHodReload.forEach((store) => {
      if (module.hot.data && module.hot.data[store.constructor.name]) {
        store.fromJson(module.hot.data[store.constructor.name]);
      }
      module.hot.dispose((data) => {
          data[store.constructor.name] = store.asJson;
      });
    });
  }
}
