import React, {Component} from 'react';
import {object, bool} from 'prop-types';
import moment from 'moment';
import Page from './../../Page';
import Card from './../../Page/Card';
import CardTitle from './../../Page/Card/CardTitle';
import CardBody from './../../Page/Card/CardBody';
import {LinkButton} from './../../Button';
import Typography from '../../Page/Typography';

class UpcomingSessionPage extends Component {
  render () {
    const {session, withArchive} = this.props;

    return (
      <Page>
        <Card>
          <CardTitle>
            No Active Session
          </CardTitle>

          <CardBody centered>
            <Typography type='body1'>
              You can begin accessing {'"'}{session.title}{'"'} starting on {moment(session.date).format('LLLL')}
            </Typography>

            {withArchive ? <LinkButton to='/archive'>View previous sessions</LinkButton> : null}
          </CardBody>
        </Card>
      </Page>
    );
  }
}

UpcomingSessionPage.propTypes = {
  session: object,
  withArchive: bool
};

export default UpcomingSessionPage;
