import React, {Component} from 'react';
import {inject, observer} from 'mobx-react';
import {object} from 'prop-types';
import LoadingPage from './../LoadingPage';
import ErrorPage from './../ErrorPage';
import Page from './../../Page';
import Card from './../../Page/Card';
import CardTitle from './../../Page/Card/CardTitle';
import ModuleComponent from './ModuleComponent';

@inject('uiStore') @inject('moduleStore') @observer
class ModuleSessionsStepPage extends Component {
  componentDidMount () {
    const {uiStore, moduleStore, match: {params: {id}}} = this.props;

    uiStore.title = '';
    uiStore.backUrl = '/module';

    moduleStore.loadStep(id);
  }

  render () {
    const {moduleStore, match: {params: {id}}} = this.props;

    if (moduleStore.loadError) {
      return (
        <ErrorPage text={moduleStore.loadError} />
      );
    }

    if (moduleStore.loading || !moduleStore.getStep(id)) {
      return (<LoadingPage />);
    }

    return (
      <Page>
        <Card noSidePadding>
          <CardTitle>
            {moduleStore.getStep(id).title}
          </CardTitle>

          {moduleStore.getStep(id).components.map((component) => (
            <ModuleComponent key={component._id} stepId={id} component={component} />
          ))}
        </Card>
      </Page>
    );
  }
}

ModuleSessionsStepPage.propTypes = {
  uiStore: object,
  moduleStore: object,
  match: object
};

export default ModuleSessionsStepPage;
