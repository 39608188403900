import {observable, action, transaction, autorun, computed} from 'mobx';
import moment from 'moment';

const STORE_KEY = 'auth_store';

export default class AuthStore {
  @observable.ref token = null;
  @observable.ref refreshToken = null;
  @observable.ref loading = false;
  @observable.ref user = {};
  @observable.ref loginToResetPassword = null;
  @observable.ref resetPasswordWasRequested = false;
  @observable.ref passwordWasReset = false;

  constructor(transportLayer, store) {
    this.transportLayer = transportLayer;

    transportLayer.authenticator = this;

    if (store) {
      this.fromJson(store.get(STORE_KEY) || {});

      if (this.authenticated) {
        this.loadUser();
      }

      autorun(() => {
        store.set(STORE_KEY, this.asJson);
      });
    }
  }

  @computed get asJson () {
    return {
      token: this.token,
      refreshToken: this.refreshToken,
      loginToResetPassword: this.loginToResetPassword,
    };
  }

  fromJson (json) {
    this.token = json.token || null;
    this.refreshToken = json.refreshToken || null;
    this.loginToResetPassword = json.loginToResetPassword || null;
  }

  @computed get authenticated () {
    return !!this.token;
  }

  @action async login (login, password) {
    await this.getToken({login, password, scope: 'app'});
  }

  @action async refresh () {
    await this.getToken({refresh_token: this.refreshToken});
  }

  @action async getToken (credentials) {
    this.loading = true;

    try {
      const {data} = await this.transportLayer.post('auth/tokens', {...credentials, scope: 'app'});

      transaction(() => {
        this.loading = false;
        this.token = data.token;
        this.refreshToken = data.refresh_token;
      });

      await this.loadUser();
    } catch (err) {
      this.loading = false;

      this.handleError(err);
    }
  }

  @action async createResetCode (login) {
    this.loading = true;

    try {
      await this.transportLayer.post('auth/password_reset_codes', {login, initiator: 'sessions'});

      transaction(() => {
        this.loading = false;

        this.loginToResetPassword = login;
        this.resetPasswordWasRequested = true;
      });
    } catch (err) {
      this.loading = false;

      this.handleError(err);
    }
  }

  @action async setPassword (credentials, password) {
    this.loading = true;

    try {
      const {data} = await this.transportLayer.post('auth/password', {password, ...credentials});

      transaction(() => {
        this.token = data.token;
        this.refreshToken = data.refresh_token;
        this.passwordWasReset = true;
      });

      await this.loadUser();

      this.loading = false;
    } catch (err) {
      this.loading = false;

      this.handleError(err);
    }
  }

  @action async loadUser () {
    this.loading = true;

    try {
      const {data: user} = await this.transportLayer.get('users/me');

      transaction(() => {
        this.loading = false;
        this.user = user;
      });
    } catch (err) {
      this.loading = false;

      this.handleError(err);
    }
  }

  @action logout () {
    this.token = null;
    this.refreshToken = null;
  }

  handleError(err) {
    if (err.code === 'FB001') {
      const handledError = new Error();

      if (err.meta && err.meta.inactive) {
        handledError.message = `Can't recognize your email/password combination. `
        + `Make sure you've registered in the app first and use the same login info.`;
      } else {
        handledError.message = `The login and password doesn't match any account`;
      }


      throw handledError;
    }

    if (err.code === 'BR016') {
      const handledError = new Error();

      handledError.message = `
      Too many requests registered. Please try it later at ${moment(err.meta.available_at).format('LT')}
      `;

      throw handledError;
    }

    throw err;
  }
}
