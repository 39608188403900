import React, {Component} from 'react';
import {string} from 'prop-types';
import styled from 'styled-components';

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  background: rgba(0, 0, 0, 0.8);
`;

const Video = styled.iframe.attrs({
  frameBorder: 0,
  allow: 'autoplay; encrypted-media'
})`
  width: 100%;
  height: 100%;
  box-sizing: border-box;
`;

class ArchivedSessionVideo extends Component {
  render () {
    const {videoId} = this.props;

    return (
      <Container>
        <Video src={`https://player.vimeo.com/video/${videoId}`} innerRef={this.videoRef} />
      </Container>
    );
  }
}

ArchivedSessionVideo.propTypes = {
  videoId: string
};

export default ArchivedSessionVideo;
