import React, {Component} from 'react';
import {inject, observer} from 'mobx-react';
import {Redirect} from 'react-router-dom';
import MobxReactForm from 'mobx-react-form';
import {object} from 'prop-types';
import loginForm from './../../forms/loginForm';
import LoadingPage from './../LoadingPage';
import Page from './../../Page';
import Card from './../../Page/Card';
import CardTitle from './../../Page/Card/CardTitle';
import CardBody from './../../Page/Card/CardBody';
import Form from './../../Form';
import FormError from './../../Form/FormError';
import Input from './../../Form/Input';
import InputError from './../../Form/InputError';
import Button from './../../Button';
import Link from './../../Link';
import PasswordInput from "../../Form/PasswordInput";

@inject('uiStore') @inject('authStore') @observer
class LoginPage extends Component {
  componentDidMount () {
    const {uiStore, authStore} = this.props;

    uiStore.title = 'Login';
    uiStore.backUrl = '';

    authStore.loginToResetPassword = null;
  }

  handleAuth = async (form) => {
    const {login, password} = form.values();
    const {authStore} = this.props;

    try {
      await authStore.login(login, password);
    } catch (err) {
      if (err.message) {
        form.invalidate(err.message);
      }
    }
  }

  form = new MobxReactForm(loginForm, {
    hooks: {
      onSuccess: this.handleAuth
    }
  })

  render () {
    const {props: {authStore: {loading, authenticated}}, form} = this;

    if (loading) {
      return (<LoadingPage />);
    }

    if (authenticated) {
      return <Redirect to={{pathname: '/'}} />;
    }

    return (
      <Page>
        <Card width={500}>
          <CardTitle>
            Positive Intelligence Sessions
          </CardTitle>
          <CardBody centered>
            <Form onSubmit={form.onSubmit}>
              <Input invalid={!!form.$('login').error} {...form.$('login').bind()} />
              {form.$('login').error ?
                <InputError>{form.$('login').error}</InputError>
              : null}

              <PasswordInput invalid={!!form.$('password').error} {...form.$('password').bind()} />
              {form.$('password').error ?
                <InputError>{form.$('password').error}</InputError>
              : null}

              <Button onClick={form.onSubmit} type='submit'>Login</Button>
              {form.error ?
                <FormError>{form.error}</FormError>
              : null}
              <br />
              <Link to='/forgot_password'>Forgot Password?</Link>
            </Form>
          </CardBody>
        </Card>
      </Page>
    );
  }
}

LoginPage.propTypes = {
  authStore: object
};

export default LoginPage;
