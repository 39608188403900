import {confirmPassword, password, required} from './validators';

export default {
  fields: [{
    name: 'new_password',
    label: 'New Password',
    placeholder: 'New Password',
    validators: [required, password]
  }, {
    name: 'confirm_password',
    label: 'Confirm Password',
    placeholder: 'Confirm Password',
    validators: [required, confirmPassword]
  }]
};
