import React from 'react';
import {Link} from 'react-router-dom';
import styled, {css} from 'styled-components';
import media from './../media';

const style = css`
  display: inline-block;
  box-sizing: border-box;
  font-size: 16px;
  font-weight: bold;
  color: #ffffff;
  text-align: center;
  text-transform: uppercase;
  text-decoration: none;
  background-color: #2EB0C6;
  border: none;
  border-radius: 50px;
  padding: ${({small}) => small ? '8px 16px' : '16px 48px'};
  margin: ${({small}) => small ? '0' : '16px 0'};

  width: ${({stretch}) => stretch ? '100%' : 'auto'};
  min-width: ${({width}) => width || '162px'};

  opacity: ${({disabled}) => disabled ? 0.5 : 1};
  cursor: ${({disabled}) => disabled ? 'normal' : 'pointer'};

  ${media.mobile`
    padding: ${({small}) => small ? '4px 8px' : '8px 16px'};
    font-size: 14px;
  `}
`;

const Button = styled.button`
  ${style}
`;

export const LinkButton = styled(({stretch, width, disabled, small,  ...props}) => (<Link {...props} />))`
  ${style}
`;

export default Button;
