import React, {Component} from 'react';
import {inject, observer} from 'mobx-react';
import {Route, Redirect} from 'react-router-dom';

@inject('authStore') @observer
export default class PrivateRoute extends Component {
  renderComponent = (props) => {
    const {component: Component, authStore} = this.props;

    if (authStore.authenticated) {
      return <Component {...props} />;
    }

    return (
      <Redirect to={{
        pathname: '/login',
        query: {next: props.location}
      }} />
    );
  }

  render () {
    const {component, authStore, ...rest} = this.props;

    // force mobx observe as it won't observe props accessed in callback like 'renderComponent'
    (authStore.authenticated);

    return (
      <Route {...rest} render={this.renderComponent} />
    );
  }
}
