import styled from 'styled-components';

const CardBody = styled.div`
  font-size: 16px;
  line-height: 1.5;
  text-align: left;
  color: #3A3A3A;
  overflow: hidden;

  ${({centered}) => (centered ? 'text-align: center;' : null)}
`;

export default CardBody;
