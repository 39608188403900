export const required = ({field: {value}}) => [!!(value || value === 0), 'The field is required'];

export const minlength = (l) => ({field: {value}}) => {
  return [!!(value && value.length >= l), `Min length is ${l}`];
};

export const email = ({field: {value}}) => {
  const regex = /^([\w\-+]+(?:\.[\w\-+]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,66}(?:\.[a-z]{2})?)$/i;
  return [!(value && !regex.test(value)), 'Invalid email'];
};

export const password = ({field: {value}}) => {
  const password = value || '';

  const upperCaseCount = password.match(/[A-Z]/g);
  const numericCount = password.match(/[0-9]/g);
  const symbolCount = password.match(/[^a-zA-Z0-9]/g);
  const meetsMin = password.length >= 8;
  const meetsUppercase = upperCaseCount && upperCaseCount.length >= 1;
  const meetsNumeric = numericCount && numericCount.length >= 1;
  const meetsSymbol = symbolCount && symbolCount.length >= 1;

  return [meetsMin && meetsUppercase && meetsNumeric && meetsSymbol, 'Password should be at least 8 characters long, contain uppercase letter, number and a special character.'];
};

export const confirmPassword = ({field, form}) => {
  const fieldsAreEquals = (form.$('new_password').value === field.value);

  return [fieldsAreEquals, 'The password confirmation does not match.'];
};
