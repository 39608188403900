import {observable, action, transaction} from 'mobx';

export default class ModuleStore {
  @observable.ref loading = false;
  @observable.ref loadError = null;
  @observable.ref module = null;
  @observable.ref steps = {};
  @observable.ref components = {};
  completeComponents = {};

  constructor(transportLayer) {
    this.transportLayer = transportLayer;
  }

  @action async load () {
    this.loading = true;

    try {
      const {data} = await this.transportLayer.get('modules/video_sessions');

      transaction(() => {
        this.loading = false;
        this.module = data;
      });
    } catch (err) {
      this.loading = false;
      this.loadError = err.message;

      throw err;
    }
  }

  @action async loadStep (id) {
    if (!this.module) {
      await this.load();
    }

    this.loading = true;

    try {
      const {data} = await this.transportLayer.get(
        `modules/${this.module.module._id}/steps/${id}?progress=${this.module.progress._id}`
        );

      transaction(() => {
        this.loading = false;
        this.steps = {
          ...this.steps,
          [id]: data
        };
      });
    } catch (err) {
      this.loading = false;
      this.loadError = err.message;

      throw err;
    }
  }

  @action async loadComponent (id, stepId) {
    if (!this.module) {
      await this.load();
    }

    this.loading = true;

    try {
      const {data} = await this.transportLayer.get(
        `modules/${this.module.module._id}/steps/${stepId}/components/${id}?progress=${this.module.progress._id}`
      );

      transaction(() => {
        this.loading = false;
        this.components = {
          ...this.components,
          [id]: data
        };

        if (data.completed) {
          this.completeComponents[id] = true;
        }
      });
    } catch (err) {
      this.loading = false;
      this.loadError = err.message;

      throw err;
    }
  }

  async completeComponent (id) {
    if (!this.completeComponents[id]) {
      this.completeComponents[id] = true;

      await this.transportLayer.post(
        `modules/${this.module.module._id}/progresses/${this.module.progress._id}/components/${id}`,
        {completed_at: new Date()}
      );
    }
  }

  getStep (id) {
    return this.steps[id];
  }

  getComponent (id) {
    return this.components[id];
  }
}
