import {required, email} from './validators';

export default {
  fields: [{
    name: 'login',
    label: 'Email',
    placeholder: 'Email',
    type: 'email',
    validators: [required, email]
  }, {
    name: 'password',
    label: 'Password',
    placeholder: 'Password',
    validators: [required]
  }],
};
