import React from "react";
import ReactDOM from "react-dom";
import {AppContainer} from "react-hot-loader";
import Root from "./components/Root";

require("./favicon.ico");
require("./favicon-16x16.png");
require("./favicon-32x32.png");
// required as background image for body
require("./img/back.png");

const render = (AppRoot) => {
  ReactDOM.render(
    <AppContainer>
      <AppRoot />
    </AppContainer>,
    document.getElementById("app")
  );
};

render(Root);

if (module.hot) {
  module.hot.accept("./components/Root", () => {
    const NewRoot = require("./components/Root").default;
    render(NewRoot);
  });
}
