import styled from 'styled-components';

const InputHelper = styled.div`
  text-align: left;
  font-size: 14px;
  margin: -6px 0 6px 14px;
  color: #d80000;
`;

export default InputHelper;
