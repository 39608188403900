import React, {Component} from 'react';
import {inject, observer} from 'mobx-react';
import {object} from 'prop-types';
import LoadingPage from './../LoadingPage';
import ErrorPage from './../ErrorPage';
import Page from './../../Page';
import ModuleSessionsVideo from './ModuleSessionsVideo';

@inject('uiStore') @inject('moduleStore') @observer
class ModuleSessionsComponentPage extends Component {
  constructor(props) {
    super(props);

    this.handleComplete = this.handleComplete.bind(this);
  }


  componentDidMount () {
    const {uiStore, moduleStore, match: {params: {id, stepId}}} = this.props;

    uiStore.title = '';
    uiStore.backUrl = `/module/steps/${stepId}`;

    moduleStore.loadComponent(id, stepId);
  }

  handleComplete () {
    const {moduleStore, match: {params: {id}}} = this.props;

    moduleStore.completeComponent(id);
  }

  render () {
    const {moduleStore, match: {params: {id}}} = this.props;

    if (moduleStore.loadError) {
      return (
        <ErrorPage text={moduleStore.loadError} />
      );
    }

    if (moduleStore.loading || !moduleStore.getComponent(id)) {
      return (<LoadingPage />);
    }

    return (
      <Page>
        <ModuleSessionsVideo url={moduleStore.getComponent(id).data.link.embed}
          onComplete={this.handleComplete}
         />
      </Page>
    );
  }
}

ModuleSessionsComponentPage.propTypes = {
  uiStore: object,
  moduleStore: object,
  match: object
};

export default ModuleSessionsComponentPage;
