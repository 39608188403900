import React, {Component} from 'react';
import styled from 'styled-components';
import {Link} from 'react-router-dom';
import {object, func, string} from 'prop-types';
import logoutIcon from './../../img/logout.svg';
import backIcon from './../../img/arrow_back.svg';
import media from './../../media';

const Container = styled.div`
  width: 100%;
  height: 40px;
  padding: 0 16px;
  box-sizing: border-box;
  position: relative;
  z-index: 1;
  background-color: #ffffff;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.5);
`;

const CenterSection = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  color: #3A3A3A;
`;

const LeftSection = styled.div`
  position: absolute;
  top: 0;
  left: 16px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const RightSection = styled.div`
  position: absolute;
  top: 0;
  right: 16px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Title = styled.div`
  font-size: 18px;
  font-weight: bold;
  color: #21b0c7;
  padding: 0 32px;

  ${media.mobile`
    font-size: 14px;
  `}
`;

const User = styled.div`
  color: #3A3A3A;
  margin-right: 16px;

  ${media.mobile`
    display: none;
  `}
`;

const LogoutIcon = styled.img`
  height: 24px;
  width: 24px;
  cursor: pointer;
`;

const Back = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
`;

const BackIcon = styled.img`
  height: 24px;
  width: 24px;
  cursor: pointer;
`;

const BackText = styled.span`
  color: #3A3A3A;
  margin-left: 8px;

  ${media.mobile`
    display: none;
  `}
`;

class Header extends Component {
  render () {
    const {user, title, backUrl, onLogout} = this.props;

    return (
      <Container>
        {backUrl ? <LeftSection>
          <Back to={backUrl}>
            <BackIcon src={backIcon} />
            <BackText>Back</BackText>
          </Back>
        </LeftSection> : null}
        {title ? <CenterSection>
          <Title>{title}</Title>
        </CenterSection> : null}
        {user ? <RightSection>
          <User>
            {user.first_name} {user.last_name}
          </User>
          <LogoutIcon onClick={onLogout} src={logoutIcon} />
        </RightSection> : null}
      </Container>
    );
  }
}

Header.propTypes = {
  backUrl: string,
  title: string,
  user: object,
  onLogout: func
};

export default Header;
