import pick from 'lodash/pick';
import {observable, computed, autorun} from 'mobx';

const STORE_KEY = 'ui_store';

export default class UIStore {
  @observable.ref title = '';
  @observable.ref backUrl = '';
  @observable.ref showChat = true;

  constructor(store) {
    if (store) {
      this.fromJson(store.get(STORE_KEY) || {});

      if (this.authenticated) {
        this.loadUser();
      }

      autorun(() => {
        store.set(STORE_KEY, pick(this.asJson, ['showChat']));
      });
    }
  }

  @computed get asJson () {
    return pick(this, ['title', 'backUrl', 'showChat']);
  }

  fromJson (json) {
    Object.assign(this, json);
  }
}
