import React, {Component} from 'react';
import {inject, observer} from 'mobx-react';
import {object, string} from 'prop-types';
import Page from './../Page';
import Card from './../Page/Card';
import CardTitle from './../Page/Card/CardTitle';

@inject('uiStore') @observer
class ErrorPage extends Component {
  componentDidMount () {
    const {uiStore} = this.props;

    uiStore.title = '';
    uiStore.backUrl = '';
  }

  render () {
    const {text} = this.props;

    return (
      <Page>
        <Card>
          <CardTitle>
            {text}
          </CardTitle>
        </Card>
      </Page>
    );
  }
}

ErrorPage.propTypes = {
  uiStore: object,
  text: string
};

export default ErrorPage;
