import React, {Component} from 'react';
import moment from 'moment';
import {object} from 'prop-types';
import styled from 'styled-components';
import {Link} from 'react-router-dom';
import media from './../../../media';
import Typography from './../../Page/Typography';
import playIcon from './../../../img/play_circle_outline.svg';

const Container = styled(Link)`
  width: 100%;
  box-sizing: border-box;
  display: flex;
  justify-content: left;
  align-items: center;
  text-decoration: none;
  cursor: pointer;
  padding: 8px 32px;

  &:hover {
    background: rgba(0, 0, 0, 0.05);
  }

  ${media.mobile`
    padding: 8px;
  `}
`;

const Title = styled.div`
  flex: 1;
`;

const ViewIcon = styled.img`
  width: 24px;
  height: 24px;
`;

class ArchivedSession extends Component {
  render () {
    const {session} = this.props;

    return (
      <Container to={`/archive/${session._id}`}>
        <Title>
          <Typography type='body1'>
            {session.title}
          </Typography>
          <Typography type='caption'>
            {moment(session.date).format('LLL')}
          </Typography>
        </Title>
        <ViewIcon src={playIcon} />
      </Container>
    );
  }
}

ArchivedSession.propTypes = {
  session: object
};

export default ArchivedSession;
