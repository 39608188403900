import React from 'react';
import Page from './../Page';
import Loading from './../Loading';

const LoadingPage = () => (
  <Page>
    <Loading opacity={0} />
  </Page>
);

export default LoadingPage;
