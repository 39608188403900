import React, {Component} from 'react';
import {inject, observer} from 'mobx-react';
import {Redirect} from 'react-router-dom';
import {object} from 'prop-types';
import LoadingPage from './../LoadingPage';
import NotFoundPage from './../NotFoundPage';
import Page from './../../Page';
import ArchivedSessionVideo from './ArchivedSessionVideo';

@inject('uiStore') @inject('sessionsStore') @observer
class ViewArchivedSessionPage extends Component {
  componentDidMount () {
    const {uiStore, sessionsStore} = this.props;

    uiStore.title = 'Archive';
    uiStore.backUrl = '/archive';

    sessionsStore.loadIfNeed();
    this.setTitle();
  }

  componentDidUpdate() {
    this.setTitle();
  }

  setTitle () {
    const {uiStore} = this.props;
    const session = this.getSession();

    uiStore.title = session ? session.title : '';
  }

  getSession() {
    const {sessionsStore, match: {params: {id}}} = this.props;

    return sessionsStore.archived.find((s) => s._id === id)
      || sessionsStore.global.find((s) => s._id === id);
  }

  render () {
    const {props: {sessionsStore: {loading, useModuleSessions}}} = this;
    const session = this.getSession();

    if (useModuleSessions) {
      return <Redirect to={{pathname: '/module'}} />;
    }

    if (loading) {
      return (<LoadingPage />);
    }

    if (!session) {
      return (<NotFoundPage />);
    }

    return (
      <Page>
        <ArchivedSessionVideo videoId={session.vimeo_id} />
      </Page>
    );
  }
}

ViewArchivedSessionPage.propTypes = {
  uiStore: object,
  sessionsStore: object,
  match: object
};

export default ViewArchivedSessionPage;
