import styled from 'styled-components';
import React, {Component} from 'react';
import Input from './Input';
import showIcon from './../../img/password_show.svg';

const Container = styled.div`
  position: relative;
`;

const Icon = styled.img`
  position: absolute;
  right: 13px;
  top: 20px;
  height: 25px;
  cursor: pointer;
`;

class PasswordInput extends Component {
  state = {
    show: false
  }

  handleShow = () => {
    this.setState({show: !this.state.show});
  }

  render() {
    return <Container>
      <Input {...this.props} type={this.state.show ? 'text' : 'password'} />
      <Icon src={showIcon} onClick={this.handleShow} />
    </Container>;
  }
}

export default PasswordInput;
