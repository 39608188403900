import React, {Component} from 'react';
import {inject, observer} from 'mobx-react';
import MobxReactForm from 'mobx-react-form';
import {object} from 'prop-types';
import {Redirect} from 'react-router-dom';
import createResetCodeForm from './../../forms/createResetCodeForm';
import LoadingPage from './../LoadingPage';
import Page from './../../Page';
import Card from './../../Page/Card';
import CardTitle from './../../Page/Card/CardTitle';
import CardBody from './../../Page/Card/CardBody';
import Form from './../../Form';
import FormError from './../../Form/FormError';
import Input from './../../Form/Input';
import InputError from './../../Form/InputError';
import Button from './../../Button';

@inject('uiStore') @inject('authStore') @observer
class ForgotPasswordPage extends Component {
  componentDidMount () {
    const {uiStore, authStore} = this.props;

    uiStore.title = 'Request Password Reset Code';
    uiStore.backUrl = '';

    if (authStore.authenticated) {
      authStore.logout();
    }
  }

  handleCreateResetCode = async (form) => {
    const {login} = form.values();
    const {authStore} = this.props;

    try {
      await authStore.createResetCode(login);
    } catch (err) {
      if (err.message) {
        form.invalidate(err.message);
      }
    }
  }

  form = new MobxReactForm(createResetCodeForm, {
    hooks: {
      onSuccess: this.handleCreateResetCode
    }
  })

  render () {
    const {props: {authStore}, form} = this;

    if (authStore.loading) {
      return (<LoadingPage />);
    }

    if (authStore.resetPasswordWasRequested) {
      return <Redirect to={{pathname: '/reset_password'}} />;
    }

    return (
      <Page>
        <Card width={500}>
          <CardTitle>
            Request Password Reset Code
          </CardTitle>
          <CardBody centered>
            <Form onSubmit={form.onSubmit}>
              <Input invalid={!!form.$('login').error} {...form.$('login').bind()} />
              {form.$('login').error ?
                <InputError>{form.$('login').error}</InputError>
              : null}

              <Button onClick={form.onSubmit} type='submit'>Send code</Button>
              {form.error ?
                <FormError>{form.error}</FormError>
              : null}
            </Form>
          </CardBody>
        </Card>
      </Page>
    );
  }
}

ForgotPasswordPage.propTypes = {
  authStore: object
};

export default ForgotPasswordPage;
