import pick from 'lodash/pick';
import {observable, action, transaction, computed} from 'mobx';

export default class SessionsStore {
  @observable.ref loading = false;
  @observable.ref loadError = null;
  @observable.ref useModuleSessions = true;
  @observable.shallow archived = [];
  @observable.shallow global = [];
  @observable.ref archiveOnly = null;
  @observable.ref isInProgram = null;
  @observable.ref upcoming = null;
  @observable.ref active = null;
  @observable.ref date = null;
  @observable.ref counter = null;
  loadedAt;

  constructor(transportLayer) {
    this.transportLayer = transportLayer;

    this.startCounter();
  }

  @computed get asJson () {
    return pick(this, ['archived', 'global', 'upcoming', 'active', 'date', 'loadedAt', 'isInProgram']);
  }

  fromJson (json) {
    Object.assign(this, json);
  }

  startCounter () {
    setInterval(() => this.count(), 1000);
  }

  @computed get activeSessionStartDate () {
    if (!this.active) {
      return null;
    }

    return this.active.date - this.timeDiff;
  }

  @computed get timeDiff () {
    return this.date - this.loadedAt;
  }

  @action count () {
    let counter = null;

    if (this.upcoming) {
      counter = Math.round((this.upcoming.date - Date.now() - this.timeDiff) / 1000);

      if (counter > 60 * 60) {
        counter = null;
      } else if (counter < 1) {
        // time to start upcoming session
        counter = null;
        this.active = this.upcoming;
        this.upcoming = null;
      }
    }

    this.counter = counter;
  }

  @action async load () {
    this.loading = true;

    try {
      const {data} = await this.transportLayer.get('video_sessions');

      transaction(() => {
        this.loading = false;
        this.archived = data.archived;
        this.global = data.global;
        this.upcoming = data.upcoming;
        this.active = data.active;
        this.date = data.date;
        this.archiveOnly = data.archive_only;
        this.isInProgram = data.is_in_program;
        this.loadedAt = Date.now();
        this.count();
      });
    } catch (err) {
      this.loading = false;
      this.loadedAt = Date.now();
      this.loadError = err.message;

      if (err.code === 'BR029') {
        this.useModuleSessions = true;
      } else {
        throw err;
      }
    }
  }

  @action async loadIfNeed () {
    await this.load();
  }
}
