import React, {Component} from 'react';
import {object, string} from 'prop-types';
import styled from 'styled-components';
import {Link} from 'react-router-dom';
import media from './../../../media';
import Typography from './../../Page/Typography';
import playIcon from './../../../img/play_circle_outline.svg';
import lockIcon from './../../../img/lock_icon.svg';
import checkIcon from './../../../img/check_icon.svg';

const LockedComponent = styled.div`
  width: 100%;
  box-sizing: border-box;
  display: flex;
  justify-content: left;
  align-items: center;
  padding: 8px 32px;
  opacity: 0.5;

  ${media.mobile`
    padding: 8px;
  `}
`;

const UnlockedComponent = styled(Link)`
  width: 100%;
  box-sizing: border-box;
  display: flex;
  justify-content: left;
  align-items: center;
  text-decoration: none;
  cursor: pointer;
  padding: 8px 32px;

  &:hover {
    background: rgba(0, 0, 0, 0.05);
  }

  ${media.mobile`
    padding: 8px;
  `}
`;

const Title = styled.div`
  flex: 1;
`;

const Icon = styled.img`
  width: 24px;
  height: 24px;
`;

class ModuleComponent extends Component {
  render () {
    const {component, stepId} = this.props;

    const title = (
      <Title>
        <Typography type='body1'>
          {component.title}
        </Typography>
        <Typography type='caption'>
          {component.duration_m} min.
        </Typography>
      </Title>
    );

    return component.locked ? (
      <LockedComponent>
        {title}
        <Icon src={lockIcon} />
      </LockedComponent>
    ) : (
      <UnlockedComponent to={`/module/steps/${stepId}/components/${component._id}`}>
        {title}
        <Icon src={component.completed ? checkIcon : playIcon} />
      </UnlockedComponent>
    );
  }
}

ModuleComponent.propTypes = {
  stepId: string,
  component: object,
};

export default ModuleComponent;
