import React, {Component} from 'react';
import {inject, observer} from 'mobx-react';
import {Redirect} from 'react-router-dom';
import MobxReactForm from 'mobx-react-form';
import queryString from 'query-string';
import {object} from 'prop-types';
import resetPasswordByCodeForm from './../../forms/resetPasswordByCodeForm';
import resetPasswordByLinkForm from './../../forms/resetPasswordByLinkForm';
import LoadingPage from './../LoadingPage';
import Page from './../../Page';
import Card from './../../Page/Card';
import CardTitle from './../../Page/Card/CardTitle';
import CardBody from './../../Page/Card/CardBody';
import Form from './../../Form';
import FormError from './../../Form/FormError';
import Input from './../../Form/Input';
import InputError from './../../Form/InputError';
import Button from './../../Button';
import PasswordInput from "../../Form/PasswordInput";

@inject('uiStore') @inject('authStore') @observer
class ResetPasswordPage extends Component {
  componentDidMount () {
    const {uiStore} = this.props;

    uiStore.title = 'Set New Password';
    uiStore.backUrl = '';
  }

  handleResetPassword = async (form) => {
    const {code, new_password: password} = form.values();
    const {authStore} = this.props;
    const linkCode = this.getLinkCode();

    try {
      if (linkCode) {
        await authStore.setPassword({code: linkCode}, password);
      } else {
        await authStore.setPassword({code, login: authStore.loginToResetPassword}, password);
      }
    } catch (err) {
      if (err.message) {
        form.invalidate(err.message);
      }
    }
  }

  getLinkCode () {
    const {location: {search}} = this.props;

    return queryString.parse(search).code;
  }

  codeForm = new MobxReactForm(resetPasswordByCodeForm, {
    hooks: {
      onSuccess: this.handleResetPassword
    }
  })

  linkForm = new MobxReactForm(resetPasswordByLinkForm, {
    hooks: {
      onSuccess: this.handleResetPassword
    }
  })

  render () {
    const {props: {authStore}} = this;
    const {loading, loginToResetPassword, passwordWasReset} = authStore;
    const linkCode = this.getLinkCode();

    if (loading) {
      return (<LoadingPage />);
    }

    if (passwordWasReset || (!loginToResetPassword && !linkCode)) {
      return <Redirect to={{pathname: '/'}} />;
    }

    authStore.logout();

    return (
      <Page>
        <Card width={500}>
          <CardTitle>
            Set New Password
          </CardTitle>
          <CardBody centered>
            {this.renderForm()}
          </CardBody>
        </Card>
      </Page>
    );
  }

  renderForm () {
    const {codeForm, linkForm} = this;
    const linkCode = this.getLinkCode();

    if (linkCode) {
      return (
        <Form onSubmit={linkForm.onSubmit}>
          <PasswordInput invalid={!!linkForm.$('new_password').error} {...linkForm.$('new_password').bind()} />
          {linkForm.$('new_password').error ?
            <InputError>{linkForm.$('new_password').error}</InputError>
            : null}

          <PasswordInput invalid={!!linkForm.$('confirm_password').error} {...linkForm.$('confirm_password').bind()} />
          {linkForm.$('confirm_password').error ?
            <InputError>{linkForm.$('confirm_password').error}</InputError>
            : null}

          <Button onClick={linkForm.onSubmit} type='submit'>Set password</Button>
          {linkForm.error ?
            <FormError>{linkForm.error}</FormError>
                : null}
        </Form>
      );
    }

    return (
      <Form onSubmit={codeForm.onSubmit}>
        <Input invalid={!!codeForm.$('code').error} {...codeForm.$('code').bind()} />
        {codeForm.$('code').error ?
          <InputError>{codeForm.$('code').error}</InputError>
              : null}

        <PasswordInput invalid={!!codeForm.$('new_password').error} {...codeForm.$('new_password').bind()} />
        {codeForm.$('new_password').error ?
          <InputError>{codeForm.$('new_password').error}</InputError>
          : null}

        <PasswordInput invalid={!!codeForm.$('confirm_password').error} {...codeForm.$('confirm_password').bind()} />
        {codeForm.$('confirm_password').error ?
          <InputError>{codeForm.$('confirm_password').error}</InputError>
          : null}

        <Button onClick={codeForm.onSubmit} type='submit'>Set password</Button>
        {codeForm.error ?
          <FormError>{codeForm.error}</FormError>
              : null}
      </Form>
    );
  }
}

ResetPasswordPage.propTypes = {
  authStore: object
};

export default ResetPasswordPage;
