import React from 'react';
import styled from 'styled-components';
import {number} from 'prop-types';

const LoadingOverlay = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, ${({opacity = 0.5}) => opacity});
  text-align: center;
`;

const Loading = ({opacity}) => (
  <LoadingOverlay opacity={opacity}>
    <div className='pi-loading-icon'>
      <div />
      <div />
      <div />
      <div />
    </div>
  </LoadingOverlay>
);

Loading.propTypes = {
  opacity: number
};

export default Loading;
