import styled from 'styled-components';

const Input = styled.input`
  height: 50px;
  width: 100%;
  border-radius: 2px;
  border: 1px solid ${({invalid}) => invalid ? '#d80000' : '#eaecf0'};
  ${({readOnly}) => readOnly ? 'cursor: pointer;' : ''}
  margin: 8px 0;
  font-size: 16px;
  padding: 0 14px;
  box-sizing: border-box;
  ::placeholder {
    color: #D8D9DA;
  }
`;

export default Input;
