import React, {Component} from 'react';
import {string, func} from 'prop-types';
import styled from 'styled-components';
import Player from '@vimeo/player';

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  background: rgba(0, 0, 0, 0.8);
`;

const Video = styled.iframe.attrs({
  frameBorder: 0,
  allow: 'autoplay; encrypted-media'
})`
  width: 100%;
  height: 100%;
  box-sizing: border-box;
`;

class ModuleSessionsVideo extends Component {
  constructor(props) {
    super(props);
    this.videoRef = React.createRef();
  }

  componentDidMount () {
    const player = this.player = new Player(this.videoRef.current);

    player.on('timeupdate', ({percent}) => {
      if (percent > 0.9) {
        this.props.onComplete && this.props.onComplete();
      }
    });
  }

  render () {
    return (
      <Container>
        <Video src={this.props.url} innerRef={this.videoRef} />
      </Container>
    );
  }
}

ModuleSessionsVideo.propTypes = {
  url: string,
  onComplete: func
};

export default ModuleSessionsVideo;
