import styled from 'styled-components';
import media from './../../media';

const Typography = styled.div`
  line-height: 1.5;

  ${({type}) => type === 'body1' ? `
    color: #3A3A3A;
    font-size: 20px;
  ` : ''}

  ${({type}) => type === 'caption' ? `
    color: #777777;
    font-size: 14px;
  ` : ''}

  ${media.mobile`
    ${({type}) => type === 'body1' ? `
      font-size: 16px;
    ` : ''}

    ${({type}) => type === 'caption' ? `
        font-size: 12px;
      ` : ''}
  `}
`;

export default Typography;
