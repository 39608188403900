import styled from 'styled-components';
import {Link as RouterLink} from 'react-router-dom';

const Link = styled(RouterLink)`
  display: inline-block;
  color: #21B0C7;
  font-size: 14px;
  text-decoration: none;
  cursor: pointer;
`;

export default Link;
