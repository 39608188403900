import axios from 'axios';
import isArray from 'lodash/isArray';

export default class AxiosTransportLayer {
  constructor (baseUrl) {
    this.baseUrl = baseUrl;
  }

  get = (url) => this.makeApiRequest('get', url)
  post = (url, data) => this.makeApiRequest('post', url, data)

  async makeApiRequest (method, url, ...args) {
    const {baseUrl, authenticator} = this;

    const makeRequest = async (retry) => {
      if (authenticator) {
        if (authenticator.authenticated && url !== 'auth/tokens') {
          const i = method === 'post' ? 1 : 0;

          args[i] = args[i] || {};
          args[i].headers = args[i].headers || {};
          args[i].headers['X-Access-Token'] = authenticator.token;
        }
      }

      try {
        return await axios[method](`${baseUrl}/${url}`, ...args);
      } catch (error) {
        if (!error.response) {
          throw error;
        }

        const {status, data} = error.response;

        if (status === 401 && !retry && authenticator) {
          await authenticator.refresh();

          return makeRequest(true);
        }

        if (status === 403 && url === 'auth/tokens' && authenticator.authenticated) {
          await authenticator.logout();
        }

        const apiError = new Error('API Error');

        apiError.status = status;
        apiError.data = data;
        apiError.code = data && data.code;
        apiError.message = data && data.message;
        apiError.meta = data && data.meta;
        apiError.url = url;

        if (apiError.status === 400) {
          apiError.message = data && data.message || 'Validation error';

          if (data && data.meta) {
            if (isArray(data.meta)) {
              apiError.message += `:\n ${data.meta.map((m) => m.message || m).join('\n')}`;
            } else if (data.meta.message) {
              apiError.message += `:\n ${data.meta.message}`;
            }
          }
        }

        throw apiError;
      }
    };

    return makeRequest();
  }
}
