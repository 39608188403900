import React, {Component} from 'react';
import {object, number} from 'prop-types';
import Page from './../../Page';
import Card from './../../Page/Card';
import CardTitle from './../../Page/Card/CardTitle';

class SessionCounterPage extends Component {
  getCounterText (counter) {
    if (counter < 60) {
      return `${counter} second${counter > 1 ? 's' : ''}`;
    }

    const minutes = Math.ceil(counter / 60);

    return `${minutes} minute${minutes > 1 ? 's' : ''}`;
  }

  render () {
    const {session, counter} = this.props;

    return (
      <Page>
        <Card>
          <CardTitle>
            Your session {'"'}{session.title}{'"'} will start in <br /> {this.getCounterText(counter)}
          </CardTitle>
        </Card>
      </Page>
    );
  }
}

SessionCounterPage.propTypes = {
  session: object,
  counter: number
};

export default SessionCounterPage;
