import React from 'react';
import ErrorPage from './ErrorPage';

const NotFoundPage = () => {
  return (
    <ErrorPage text='Page Not Found' />
  );
};

export default NotFoundPage;
