import React, {Component} from 'react';
import {object} from 'prop-types';
import styled from 'styled-components';
import {Link} from 'react-router-dom';
import media from './../../../media';
import Typography from './../../Page/Typography';
import checkIcon from './../../../img/check_icon.svg';
import lockIcon from './../../../img/lock_icon.svg';

const LockedStep = styled.div`
  width: 100%;
  box-sizing: border-box;
  display: flex;
  justify-content: left;
  align-items: center;
  padding: 8px 32px;
  opacity: 0.5;

  ${media.mobile`
    padding: 8px;
  `}
`;

const UnlockedStep = styled(Link)`
  width: 100%;
  box-sizing: border-box;
  display: flex;
  justify-content: left;
  align-items: center;
  text-decoration: none;
  cursor: pointer;
  padding: 8px 32px;

  &:hover {
    background: rgba(0, 0, 0, 0.05);
  }

  ${media.mobile`
    padding: 8px;
  `}
`;

const Title = styled.div`
  flex: 1;
`;

const Icon = styled.img`
  width: 24px;
  height: 24px;
`;

class ModuleStep extends Component {
  render () {
    const {step} = this.props;

    const title = (
      <Title>
        <Typography type='body1'>
          {step.title}
        </Typography>
        <Typography type='caption'>
          {step.duration_m} min.
        </Typography>
      </Title>
    );

    return step.locked ? (
      <LockedStep>
        {title}
        <Icon src={lockIcon} />
      </LockedStep>
    ) : (
      <UnlockedStep to={`/module/steps/${step._id}`}>
        {title}
        {step.completed ? <Icon src={checkIcon} /> : null}
      </UnlockedStep>
    );
  }
}

ModuleStep.propTypes = {
  step: object
};

export default ModuleStep;
