import React, {Component} from 'react';
import Page from './../../Page';
import Card from './../../Page/Card';
import CardTitle from './../../Page/Card/CardTitle';
import CardBody from './../../Page/Card/CardBody';
import {LinkButton} from './../../Button';

class OnlyArchivedSessionsPage extends Component {
  render () {
    return (
      <Page>
        <Card>
          <CardTitle>
            No Upcoming Sessions
          </CardTitle>
          <CardBody centered>
            <LinkButton to='/archive'>View previous sessions</LinkButton>
          </CardBody>
        </Card>
      </Page>
    );
  }
}

export default OnlyArchivedSessionsPage;
