import React, {Component} from "react";
import {inject, observer} from "mobx-react";
import styled from "styled-components";
import {Helmet} from "react-helmet";
import {node, object, arrayOf, oneOfType} from "prop-types";
import Header from "./Header";

const Container = styled.div`
  height: 100%;
  width: 100%;
  position: relative;
  overflow: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Content = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
`;

@inject("uiStore")
@inject("authStore")
@observer
class Page extends Component {
  handleLogout = () => {
    this.props.authStore.logout();

    // clear all in-memory stores
    window && window.location && window.location.reload();
  };

  render() {
    const {uiStore, authStore} = this.props;

    return (
      <Container>
        <Helmet
          defaultTitle={"Sessions | Positive Intelligence"}
          titleTemplate={`%s | Sessions | Positive Intelligence`}
        />
        {authStore.authenticated ? (
          <Header
            user={authStore.user}
            onLogout={this.handleLogout}
            title={uiStore.title}
            backUrl={uiStore.backUrl}
          />
        ) : null}
        <Content>{this.props.children}</Content>
      </Container>
    );
  }
}

Page.propTypes = {
  children: oneOfType([arrayOf(node), node]),
  uiStore: object,
  authStore: object,
};

export default Page;
