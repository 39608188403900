import React, {Component} from 'react';
import {inject, observer} from 'mobx-react';
import {object} from 'prop-types';
import LoadingPage from './../LoadingPage';
import ErrorPage from './../ErrorPage';
import Page from './../../Page';
import Card from './../../Page/Card';
import CardTitle from './../../Page/Card/CardTitle';
import ModuleStep from './ModuleStep';

@inject('uiStore') @inject('moduleStore') @observer
class ModuleSessionsPage extends Component {
  componentDidMount () {
    const {uiStore, moduleStore} = this.props;

    uiStore.title = '';
    uiStore.backUrl = '';

    moduleStore.load();
  }

  render () {
    const {moduleStore} = this.props;

    if (moduleStore.loadError) {
      return (
        <ErrorPage text={moduleStore.loadError} />
      );
    }

    if (moduleStore.loading || !moduleStore.module) {
      return (<LoadingPage />);
    }

    return (
      <Page>
        <Card noSidePadding>
          <CardTitle>
            {moduleStore.module.module.title}
          </CardTitle>

          {moduleStore.module.module.steps.map((step) => (
            <ModuleStep key={step._id} step={step} />
          ))}
        </Card>
      </Page>
    );
  }
}

ModuleSessionsPage.propTypes = {
  uiStore: object,
  moduleStore: object,
  match: object
};

export default ModuleSessionsPage;
