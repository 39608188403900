import React, {Component} from 'react';
import {string, number, func} from 'prop-types';
import styled from 'styled-components';
import Player from '@vimeo/player';
import Button from './../../Button';
import Loading from './../../Loading';

const LOADING = 'LOADING';
const STARTED = 'STARTED';
const FAILED = 'FAILED';

const Container = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  background: rgba(0, 0, 0, 0.8);
`;

const Video = styled.iframe.attrs({
  frameBorder: 0,
  allow: 'autoplay; encrypted-media'
})`
  display: ${({hidden}) => hidden ? 'none' : 'block'};
  width: 100%;
  height: 100%;
  box-sizing: border-box;
`;

class SessionVideo extends Component {
  constructor(props) {
    super(props);
    this.videoRef = React.createRef();
  }

  startTime = 0
  duration = 0

  state = {
    videoState: LOADING,
    buffering: false
  }

  componentDidMount () {
    const player = this.player = new Player(this.videoRef.current);

    player.on('loaded', async () => {
      this.start(false);
    });

    player.on('ended', () => this.props.onEnd());

    player.on('bufferstart', () => {
      this.setState({buffering: true});
    });

    player.on('bufferend', () => {
      this.setState({buffering: false});

      this.setCorrectVideoTime();
    });

    // prevent playback rate changing
    player.on('playbackratechange', () => player.setPlaybackRate(1));
  }

  componentWillUnmount () {
    this.player && this.player.destroy();
  }

  getDuration = async () => {
    if (this.duration) {
      return this.duration;
    }

    return await this.player.getDuration();
  }

  start = async (forced) => {
    const {player, props: {startedAt, onEnd}} = this;
    const duration = await this.getDuration();

    this.setState({videoState: LOADING});

    // check if video is already ended
    if (((Date.now() - startedAt) / 1000) >= duration) {
      onEnd();
      return;
    }

    try {
      await player.play();

      await this.setCorrectVideoTime();

      player.on('timeupdate', this.handleTimeupdate);

      if (forced) {
        setTimeout(this.reportFailedStart, 5000);
      } else {
        setTimeout(this.checkIfStarted, 3000);
      }
    } catch (err) {
      if (forced) {
        this.reportFailedStart();
      } else {
        this.handleFailedStart();
      }
    }
  }

  setCorrectVideoTime = async () => {
    const {player, props: {startedAt, onEnd}} = this;
    const duration = await this.getDuration();
    const videoTime = await player.getCurrentTime();
    const actualTime = (Date.now() - startedAt) / 1000;
    const diff = Math.abs(actualTime - videoTime);

    if (actualTime >= duration) {
      onEnd();
      return;
    }

    if (!this.startTime) {
      // setting the time for the first time
      this.startTime = actualTime;
      await player.setCurrentTime(actualTime);
    } else if (diff > 2) {
      await player.setCurrentTime(actualTime);
    }
  }

  handleTimeupdate = (progress) => {
    const seconds = progress.seconds - this.startTime;

    if (seconds < 0.25) {
      return;
    }

    this.player.off('timeupdate', this.handleTimeupdate);

    this.setState({videoState: STARTED});
  }

  checkIfStarted = () => {
    if (this.state.videoState !== STARTED) {
      this.handleFailedStart();
    }
  }

  handleFailedStart = () => {
    this.setState({videoState: FAILED});

    this.player.off('timeupdate', this.handleTimeupdate);
  }

  reportFailedStart = () => {
    if (this.state.videoState !== STARTED) {
      if (window && window.__Raven) {
        window.__Raven.captureMessage('Failed to start active video session', {level: 'warning'});
      }
    }
  }

  handleJoinClick = () => {
    this.start(true);
  }

  render () {
    const {props: {videoId}, state: {videoState, buffering}} = this;

    return (
      <Container>
        {(videoState === LOADING) || ((videoState === STARTED) && buffering)
        ? <Loading opacity={0} />
        : null}
        {videoState === FAILED ? <Button onClick={this.handleJoinClick}>Join the session</Button> : null}
        <Video
          src={`https://player.vimeo.com/video/${videoId}?background=1&muted=0&loop=0&autoplay=0`}
          innerRef={this.videoRef}
          hidden={videoState !== STARTED}
        />
      </Container>
    );
  }
}

SessionVideo.propTypes = {
  videoId: string,
  startedAt: number,
  onEnd: func
};

export default SessionVideo;
