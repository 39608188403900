import React, {Component} from 'react';
import styled from 'styled-components';
import {bool, func} from 'prop-types';
import Button from './../../Button';
import media from './../../../media';

const Container = styled.div`
  height: 180px;
  max-height: 30%;
  min-height: 110px;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  background: #ffffff;
  font-size: 16px;

  ${media.mobile`
    font-size: 14px;
  `}
`;

const Header = styled.div`
  position: relative;
  text-align: center;
  font-size: 18px;
  font-weight: bold;
  color: #21b0c7;
  padding: 8px;
  background: #ffffff;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.5);

  ${media.mobile`
    padding: 4px;
    font-size: 14px;
  `}
`;

const HeaderButton = styled.div`
  position: absolute;
  top: 8px;
  right: 8px;
  font-size: 16px;
  font-weight: normal;
  text-transform: uppercase;
  cursor: pointer;
  margin-right: 8px;

  ${media.mobile`
    font-size: 14px;
    top: 4px;
  `}
`;

const Content = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px;
  color: #b7b7b7;
`;

const Footer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px;
  box-sizing: border-box;
`;

const Input = styled.input`
  display: block;
  height: 32px;
  flex: 1;
  padding: 0 14px;
  box-sizing: border-box;
  ::placeholder {
    color: #D8D9DA;
  }
  margin-right: 8px;

  ${media.mobile`
    height: 24px;
  `}
`;

class Chat extends Component {
  render () {
    const {show, onTrigger} = this.props;

    if (!show) {
      return (
        <Header>
          Chat
          <HeaderButton onClick={onTrigger}>Show</HeaderButton>
        </Header>
      );
    }

    return (
      <Container>
        <Header>
          Chat
          <HeaderButton onClick={onTrigger}>Hide</HeaderButton>
        </Header>
        <Content>Chat is disabled</Content>
        <Footer>
          <Input placeholder='Enter your message...' disabled />
          <Button width={100} small disabled>Send</Button>
        </Footer>
      </Container>
    );
  }
}

Chat.propTypes = {
  show: bool,
  onTrigger: func
};

export default Chat;
