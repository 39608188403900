import styled from 'styled-components';
import media from './../../../media';

const CardTitle = styled.div`
  font-size: 24px;
  line-height: 33px;
  font-weight: bold;
  text-align: center;
  color: #21b0c7;
  margin: 16px 0 24px;

  ${media.mobile`
    font-size: 20px;
    line-height: 27px;
    margin: 16px 0 8px 0;
  `}
`;

export default CardTitle;
